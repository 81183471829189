import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { isNull } from 'lodash';

import { history } from './helpers/history';

//import components;
import SideMenu from './components/nav/SideMenu';
import ServicesPage from './components/ServicesPage';
import ProjectsPage from './components/ProjectsPage';

import './app.css';

export default function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [keycloak] = useState(new Keycloak('/keycloak.json'));
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
          setLoggedIn(authenticated);
          setLoaded(true);       
        });
    }, []);

    const onLoginChange = (bool) => {
        setLoggedIn(bool);
    }

    let authButtonBehaviour = loggedIn ? "Logout" : "LogIn / Register";

    return( 
        <div id="app" className="App">
            {!loaded && 
                <div id="app_loading">
                    <p>Loading...</p>
                </div>
            }
            {loaded &&
                <Router history={history}>

                    <div id="work_area">
                        <SideMenu 
                            loggedIn={loggedIn}
                            behaviour={authButtonBehaviour}
                            keycloak={keycloak}
                            onLoginChange={onLoginChange}
                        />
                        <Routes>
                            <Route path="*" element={<Navigate to="/services" /> }/>
                            <Route exact path="/" element={<Navigate to="/services" /> }/>
                            <Route exact path="/services" element={<ServicesPage/>} />
                            <Route exact path="/projects" element={<ProjectsPage/>} />
                        </Routes>
                    </div>
                </Router>
            }
        </div>
    )
};