import { IconConnectionPoint1, IconFlag, IconLayers, IconCheckList, IconUserGroup, IconDesktop, IconPhoneStroke, IconUnlock, IconComponent, IconCode, IconSearch, IconServer, IconPulse, IconDelete, IconPuzzle, IconUserSetting } from '@douyinfe/semi-icons';

export const services = [
    {
        id: 1,
        name: "Pentest",
        price: {
            min: "4.000$",
            max: "1.000.000$"
        },
        range: {
            min: "1 week",
            max: "3 months"
        },
        description: "Conducting authorized penetration testing to assess the security of websites with client consent. This includes a thorough analysis of system vulnerabilities and network security protocols. Comprehensive penetration testing services are offered to identify potential weaknesses and recommend actionable solutions to enhance overall security posture.",
        icon: <IconUnlock className="sizedIcon" />,
        buttons: {
                warning: true,
                info: true,
                order: true
        },
        stack: {
            enable: true,
            name: "Instruments",
            elements: [
                { text: "SQLMap" }, { text: "Burp Suite" }, { text: "Metasploit Framework" },
                { text: "Qualys" }, { text: "OpenVAS" }, { text: "Nessus" },
                { text: "Wireshark" }, { text: "OWASP ZAP" }, { text: "Nikto" },
                { text: "Nmap" }, { text: "Kali Linux" }, { text: "Parrot Security" }
            ]
        }
    },
    {
        id: 2,
        name: "Web-development",
        price:  {
            min: "100$",
            max: "5.000$"
        },
        range: {
            min: "2 days",
            max: "3 months"
        },
        description: "Development of custom websites and web platforms utilizing various content management systems (CMS) or content management frameworks (CMF). Additionally, services in search engine optimization (SEO) and targeted advertising are available to enhance online presence and effectively reach the target audience.",
        icon: <IconCode className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "ReactJS" }, { text: "NodeJS" }, { text: "Express" },
                { text: "PHP" }, { text: "Flutter" }, { text: "Laravel" },
                { text: "Python" }, { text: "Lua" }, { text: "Keycloak" },
                { text: "Docker" }, { text: "Nginx" }, { text: "TypeScript" },
                { text: "Prometheus" }, { text: "AWS" }, { text: "Cognito" },
                { text: "Bash" }
            ]
        }
    },
    {
        id: 3,
        name: "System administration",
        price:  {
            min: "500$",
            max: "6.000$"
        },
        range: {
            min: "1 day",
            max: "1 week"
        },
        description: "Services for the administration and management of your organization's network infrastructure, including servers and workstations, administration of telephone systems such as Asterisk, management of proxy servers and mail servers, administration of database servers, VPN servers, design and optimization of network architecture.",
        icon: <IconUserSetting className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Tech",
            elements: [
                { text: "GNU/Linux" }, { text: "FreeBSD" }, { text: "OpenBSD" },
                { text: "Solaris" }, { text: "VMWare ESXi" }, { text: "QubesOS" },
                { text: "VoIP Servers" }, { text: "VPN Servers" }, { text: "Terminal Servers" },
                { text: "Database Servers" }, { text: "Email Servers" }, { text: "Web Servers" }
            ]
        }
    },
    {
        id: 4,
        name: "Server programming",
        price:  {
            min: "1.250$",
            max: "15.000$"
        },
        range: {
            min: "1 week",
            max: "8 months"
        },
        description: "Development diverse applications tailored for server architecture, utilizing a wide array of databases and operating systems. Expertise encompasses the entire application lifecycle, from initial concept and design to development, testing, and deployment. Upon completion of the development phase, comprehensive installation and configuration services are provided to deploy applications on server infrastructure.",
        icon: <IconServer className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "NodeJS" }, { text: "Ruby" }, { text: "C++" },
                { text: "Lua" }, { text: "Objective-C" }, { text: "Python" },
                { text: "Bash" }
            ]
        }
    },
    {
        id: 5,
        name: "Desktop programming",
        price:  {
            min: "500$",
            max: "7.500$"
        },
        range: {
            min: "1 week",
            max: "1 month"
        },
        description: "Development of a wide range of applications designed specifically for desktop and workstation environments, utilizing various databases and operating systems. Expertise includes the entire application lifecycle, from initial concept and design through development, testing, and deployment.",
        icon: <IconDesktop className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "C#" }, { text: "C++" }, { text: "Delphi" },
                { text: "Lua" }, { text: "Objective-C" }, { text: "Python" },
                { text: "Assembler" }, { text: "Swift" }, { text: "Qt" },
                { text: "Electron" }, { text: "GTK" }, { text: "Bash" }
            ]
        }
    },
    {
        id: 6,
        name: "Database development",
        price:  {
            min: "2.000$",
            max: "150.000$"
        },
        range: {
            min: "1 week",
            max: "4 months"
        },
        description: "Services for the extraction and analysis of existing databases aim to optimize and redesign their structure and functionality. This process involves a thorough assessment of current database systems to identify areas for improvement, ensuring that the data architecture aligns with evolving business requirements. Services include the design and implementation of new database systems tailored to specific needs.",
        icon: <IconLayers className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Databases",
            elements: [
                { text: "MySQL" }, { text: "PostgreSQL" }, { text: "SQL Lite" },
                { text: "MariaDB" }, { text: "MongoDB" }, { text: "DynamoDB" }
            ]
        }
    },
    {
        id: 7,
        name: "Mobile programming",
        price:  {
            min: "200$",
            max: "10.000$"
        },
        range: {
            min: "1 week",
            max: "6 months"
        },
        description: "Mobile application development for iOS and Android platforms. Full development cycle, including requirements analysis, interface design, development, testing, deployment, and ongoing support. Development of applications of any complexity, both native and cross-platform. Stages of testing and implementation are also included. Additionally, support and updates for existing applications are available.",
        icon: <IconPhoneStroke className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "Swift" }, { text: "Flutter" }, { text: "Kotlin" },
                { text: "React Native" }, { text: "Java" }, { text: "Objective-C" },
            ]
        }
    },
    {
        id: 8,
        name: "GameDev",
        price:  {
            min: "500$",
            max: "undefined"
        },
        range: {
            min: "1 week",
            max: "2 years"
        },
        description: "Comprehensive game development. The service includes a complete cycle of creating game projects, starting from conceptual design and ending with the final release. Within this service, requirements analysis, gameplay mechanics development, scriptwriting, artistic design, and programming are carried out. Stages of testing and implementation are also included. Additionally, support and updates for existing games are available.",
        icon: <IconFlag className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Engines",
            elements: [
                { text: "Unity3D" }, { text: "Unreal Engine 5" }, { text: "Godot Engine" }
            ]
        }
    },
    {
        id: 9,
        name: "Corporate identity",
        price:  {
            min: "1.500$",
            max: "33.000$"
        },
        range: {
            min: "1 week",
            max: "6 months"
        },
        description: "Corporate identity service offers tailored solutions for customizing corporate styles. This includes the development of a unique operating system distribution for workstations or servers, featuring a fixed group of applications, personalized wallpapers, and color schemes. Enhance brand recognition and create a cohesive visual representation that aligns with organizational values and goals.",
        icon: <IconUserGroup className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "Linux" }, { text: "Windows" }, { text: "Android" },
                { text: "iOS" }, { text: "Desktop Applications" }, { text: "Mobile Applications" },
                { text: "Web Applications" }, { text: "Hardware" }
            ]
        }
    },
    {
        id: 10,
        name: "Code analyse",
        price:  {
            min: "500$",
            max: "2.000$"
        },
        range: {
            min: "1 day",
            max: "1 month"
        },
        description: "Analysis of application code for optimization and the identification of potential vulnerabilities, including deprecated functions and backdoors, is essential for maintaining robust software performance and security.",
        icon: <IconPulse className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "Swift" }, { text: "Flutter" }, { text: "Kotlin" },
                { text: "React Native" }, { text: "Java" }, { text: "Objective-C" },
                { text: "C#" }, { text: "C++" }, { text: "Delphi" },
                { text: "Lua" }, { text: "Python" }, { text: "Assembler" },
                { text: "NodeJS" }, { text: "Ruby" }, { text: "Laravel" }
            ]
        }
    },
    {
        id: 11,
        name: "Data recovery",
        price:  {
            min: "250$",
            max: "15.000$"
        },
        range: {
            min: "1 day",
            max: "2 months"
        },
        description: "The data recovery service offers a professional solution for those who have faced information loss due to various situations, such as hard drive damage, water exposure, or formatting. Each case is considered individually, taking into account the specific characteristics of the storage device and the nature of the data loss. A professional approach ensures reliability and high-quality service, guaranteeing that all efforts will be directed toward recovering valuable information and minimizing losses.",
        icon: <IconDelete className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Targets",
            elements: [
                { text: "Database" }, { text: "HDD" }, { text: "USB" },
                { text: "SSD" }, { text: "RAID" }, { text: "Files" }
            ]
        }
    },
    {
        id: 12,
        name: "Integrations",
        price:  {
            min: "100$",
            max: "20.000$"
        },
        range: {
            min: "2 days",
            max: "4 months"
        },
        description: "Integration services provide seamless connectivity between various platforms and services, such as datafinder.com and delivery services. This enables enhanced functionality and improved workflow by ensuring that different systems communicate effectively. Tailored solutions facilitate the exchange of data and streamline processes, ultimately optimizing operational efficiency and user experience.",
        icon: <IconPuzzle className="sizedIcon" />,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "Python" }, { text: "NodeJS" }, { text: "Laravel" }
            ]
        }
    },
    {
        id: 13,
        name: "Reverse engineering",
        price:  {
            min: "1.000$",
            max: "25.000$"
        },
        range: {
            min: "1 day",
            max: "1 month"
        },
        description: "This service analyzes products or systems to identify components and functionality. It provides insights into technology and design, enabling replication or enhancement of existing solutions. Applications include software decompilation and hardware analysis. Expertise in tools and methodologies ensures thorough analysis, offering valuable documentation and recommendations for informed decision-making in product development.",
        icon: <IconComponent className="sizedIcon"/>,
        buttons: {
            warning: true,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "IDA pro" }, { text: "Ghidra" }, { text: "Radare2" },
                { text: "Hopper" }, { text: "Immunity Debugger" }, { text: "Binary Ninja" },
                { text: "JEB Decompiler" }, { text: "Frida" }, { text: "Valgrind" },
                { text: "Lua" }, { text: "Python" }
            ]
        }
    },
    {
        id: 14,
        name: "Blockchain Development",
        price:  {
            min: "10.000$",
            max: "500.000$"
        },
        range: {
            min: "4 weeks",
            max: "1 year"
        },
        description: "Blockchain development services enhance transparency and security through smart contracts, DApps, and system integration. They offer consultation for adoption, tokenization for new business models, and private blockchains for data privacy, helping businesses reduce fraud and optimize operations.",
        icon: <IconConnectionPoint1 className="sizedIcon"/>,
        buttons: {
            warning: false,
            info: true,
            order: true
        },
        stack: {
            enable: true,
            name: "Stack",
            elements: [
                { text: "Ethereum" }, { text: "Binance Smart Chain" }, { text: "Cardano" },
                { text: "Polygon" }, { text: "Hyperledger Fabric" }, { text: "Solana" },
                { text: "Go-Ethereum (Geth)" }, { text: "Substrate" }, { text: "Cosmos SDK" },
                { text: "Avalanche" }, { text: "Solidity" }, { text: "Go" },
                { text: "IPFS" }, { text: "Docker" }, { text: "Kubernetes" },
                { text: "Web3.js" }, { text: "Ethers.js" }, { text: "JavaScript" }
            ]
        }
    },
    {
        id: 15,
        name: "OSINT",
        price:  {
            min: "1.000$",
            max: "10.000$"
        },
        range: {
            min: "1 day",
            max: "1 week"
        },
        description: "Open Source Intelligence (OSINT) involves the systematic collection and analysis of publicly available information from a variety of sources to support informed decision-making. This service encompasses a wide range of data types, including public records, social media insights, news articles, web scraping, and geospatial data. The primary purpose of OSINT is to provide organizations with the necessary information to monitor their online presence, assess potential risks, and gain insights into market trends and competitor activities. By leveraging publicly accessible data, OSINT helps in identifying threats and vulnerabilities while enhancing reputation management strategies.",
        icon: <IconSearch className="sizedIcon"/>,
        buttons: {
            warning: true,
            info: true,
            order: true
        },
        stack: {
            enable: false,
            elements: []
        }
    },
    {
        id: 16,
        name: "Business by steps",
        price:  {
            min: "undefined",
            max: "undefined"
        },
        range: {
            min: "undefined",
            max: "undefined"
        },
        description: "This service offers comprehensive support for entrepreneurs starting a business. The process begins with market analysis to identify trends, customer needs, and demand. A competitive analysis follows, evaluating strengths and weaknesses of existing players, aiding in developing strategies and unique selling propositions. It includes assessing profitability, assisting with equipment procurement, and supporting software development. Entrepreneurs receive guidance in creating marketing strategies, including digital and traditional advertising, to enhance visibility and attract customers. This structured approach equips them with essential tools and knowledge for successfully launching and growing their businesses.",
        icon: <IconCheckList className="sizedIcon" />,
        buttons: {
            warning: true,
            info: true,
            order: true
        },
        stack: {
            enable: false,
            elements: []
        }
    }
]