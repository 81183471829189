import React from 'react';
import { Col, Row, Card, Typography, Button, ButtonGroup, Tag, Space, Popover } from '@douyinfe/semi-ui';
import { projects } from './data/projects';
import { isEmpty } from 'lodash';
import { FaKickstarter, FaExternalLinkAlt, FaDonate, FaTelegram  } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="grid" style={{padding: "25px", overflowY: "auto"}}>
                <Row type="flex" style={{padding: '25px', marginBottom: '50px'}}>
                    { !isEmpty(projects) ?
                        projects.map((item) => {
                            return (
                                <Col span={projects.length > 1 ? 12 : 24} order={item.id} style={{justifyContent: 'center', display: 'flex'}}>
                                    <div className="col-content">
                                        <Card 
                                            title={<Typography.Text style={{lineHeight: '32px', verticalAlign: 'middle', fontSize: '18px'}}>{item.project.icon} <b style={{paddingLeft: '8px'}}>{item.project.name}</b></Typography.Text>}
                                            style={{ maxWidth: 650, margin: "10px", minHeight: '500px', maxHeight: '500px'}}
                                            className="cardBodyHuge"
                                            headerExtraContent={
                                                <div>
                                                    <ButtonGroup type="tertiary">
                                                        {item.buttons.kickstarter.enable && 
                                                            <Popover showArrow content={<article>Kickstarter</article>} position={'top'} trigger="hover">
                                                                <Button icon={<FaKickstarter />} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                        {item.buttons.donate.enable && 
                                                            <Popover showArrow content={<article>Donate</article>} position={'top'} trigger="hover">
                                                                <Button icon={<FaDonate/>} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                        {item.buttons.website.enable && 
                                                            <Popover showArrow content={<article>Open link</article>} position={'top'} trigger="hover">
                                                                <Button icon={<FaExternalLinkAlt />} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                    </ButtonGroup>
                                                </div>
                                            }
                                        >
                                            <div style={{maxHeight: '95%', overflowY: 'auto'}}>
                                                <Typography.Text className="textBlock"><b>Code name: </b> {item.project.dev_name}</Typography.Text>
                                                <Typography.Text className="textBlock"><b>Category: </b> <Tag size="small" color='green' type="ghost" shape='circle' prefixIcon={item.project.category.icon} style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.project.category.name}</Tag></Typography.Text>
                                                <Typography.Text className="textBlock"><b>Type: </b> 
                                                    <Space wrap>
                                                        {
                                                            item.project.types.map((el) => {
                                                                return (
                                                                    <Tag size="small" color='green' type="ghost" shape='circle' prefixIcon={el.icon} style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{el.type}</Tag>
                                                                )
                                                            })
                                                        }
                                                    </Space>
                                                </Typography.Text>
                                                <Typography.Text className="textBlock"><b>Stage:</b> <Tag size="small" color='yellow' type="ghost" shape='circle' style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.project.stage}</Tag></Typography.Text>
                                                <Typography.Text className="textBlock"><b>Release date:</b> <Tag size="small" color='green' type="ghost" shape='circle' style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.project.release_date}</Tag></Typography.Text>
                                                <Typography.Text className="textBlock"><b>Description:</b> {item.project.description}</Typography.Text>
                                                <Typography.Text className="textBlock"><b>Resources: </b> 
                                                    <ButtonGroup type="tertiary">
                                                        {item.socials.x.enable && 
                                                            <Popover showArrow content={<article>News on X</article>} position={'top'} trigger="hover">
                                                                <Button icon={<FaSquareXTwitter />} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                        {item.socials.telegram.enable && 
                                                            item.socials.telegram.links.map((btn) => {
                                                                return (
                                                                    <Popover showArrow content={<article>{btn.name}</article>} position={'top'} trigger="hover">
                                                                        <Button icon={<FaTelegram />} theme="borderless" style={{color: 'white'}}/>
                                                                    </Popover>
                                                                )
                                                            })
                                                        }
                                                    </ButtonGroup>
                                                </Typography.Text>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        }):
                        <span style={{display: "block", textAlign: "center"}}>No data.</span>
                    }
                </Row>
            </div>
        );
    }
}

export default ProjectsPage;