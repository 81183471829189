import React from 'react';
import { Col, Row, Card, Typography, Button, ButtonGroup, Tag, Space, Popover } from '@douyinfe/semi-ui';
import { services } from './data/services';
import { isEmpty } from 'lodash';
import { IconCart, IconInfoCircle, IconAlertTriangle } from '@douyinfe/semi-icons';

class ServicesPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="grid" style={{padding: "25px", overflowY: "auto"}}>
                <Row type="flex" style={{padding: '25px', marginBottom: '50px'}}>
                    { !isEmpty(services) ?
                        services.map((item) => {
                            return (
                                <Col span={services.length > 1 ? 12 : 24} order={item.id} style={{justifyContent: 'center', display: 'flex'}}>
                                    <div className="col-content">
                                        <Card 
                                            title={<Typography.Text style={{lineHeight: '32px', verticalAlign: 'middle', fontSize: '18px'}}>{item.icon} <b style={{paddingLeft: '8px'}}>{item.name}</b></Typography.Text>}
                                            style={{ maxWidth: 650, margin: "10px", minHeight: '370px', maxHeight: '370px'}}
                                            className="cardBody"
                                            headerExtraContent={
                                                <div>
                                                    <ButtonGroup type="tertiary">
                                                        {item.buttons.warning && 
                                                            <Popover showArrow content={<article>Attention</article>} position={'top'} trigger="hover">
                                                                <Button icon={<IconAlertTriangle />} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                        {item.buttons.info && 
                                                            <Popover showArrow content={<article>Info</article>} position={'top'} trigger="hover">
                                                                <Button icon={<IconInfoCircle />} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                        {item.buttons.order && 
                                                            <Popover showArrow content={<article>Order</article>} position={'top'} trigger="hover">
                                                                <Button icon={<IconCart />} theme="borderless" style={{color: 'white'}}/>
                                                            </Popover>
                                                        }
                                                    </ButtonGroup>
                                                </div>
                                            }
                                        >                                            
                                            <div style={{maxHeight: '95%', overflowY: 'auto'}}>
                                                <Typography.Text className="textBlock"><b>Price:</b> from <Tag size="small" color='green' style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.price.min}</Tag> to <Tag size="small" color='red' style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.price.max}</Tag></Typography.Text>
                                                <Typography.Text className="textBlock"><b>Range:</b> from <Tag size="small" color='green' style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.range.min}</Tag> to <Tag size="small" color='red' style={{fontWeight: "bold", lineHeight: "24px", verticalAlign: "baseline"}}>{item.range.max}</Tag></Typography.Text>
                                                <Typography.Text className="textBlock"><b>Description:</b> {item.description}</Typography.Text>
                                                {
                                                    item.stack.enable && 
                                                        <Typography.Text className="textBlock"><b style={{padding: "0 7px 0 0"}}>{item.stack.name}:</b> 
                                                            <Space wrap>
                                                                {
                                                                    item.stack.elements.map((el) => {
                                                                        return (
                                                                            <Tag size="large" color="white" type="ghost" shape='circle' style={{lineHeight: "24px", verticalAlign: "baseline"}}>{el.text}</Tag>
                                                                        )
                                                                    })
                                                                }
                                                            </Space>
                                                        </Typography.Text>
                                                }
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        }):
                        <span style={{display: "block", textAlign: "center"}}>No data.</span>
                    }
                </Row>
            </div>
        );
    }
}

export default ServicesPage;

