import React from 'react';
import { Nav, Divider } from '@douyinfe/semi-ui';
import { IconInfoCircle, IconMailStroked1, IconHome, IconShield, IconSearch, IconWrench, IconCommentStroked, IconCustomerSupportStroked, IconUser, IconSetting, IconCart, IconTerminal, IconSourceControl, IconCreditCard, IconIdCard, IconDownloadStroked, IconPieChartStroked, IconBriefStroked, IconChecklistStroked} from '@douyinfe/semi-icons';

import LoginMenu from '../login/Login';

const private_menu = [
    { 
        itemKey: 'account', 
        text: 'Account', 
        icon: <IconUser />,
        items: [
            {
                itemKey: 'profile', 
                text: 'Profile', 
                link: '/profile',
                icon: <IconIdCard />, 
            },
            {
                itemKey: 'billing', 
                text: 'Billing', 
                link: '/billing',
                icon: <IconCreditCard />, 
            },
            {
                itemKey: 'downloads', 
                text: 'Downloads', 
                link: '/downloads',
                icon: <IconDownloadStroked />, 
            },
            {
                itemKey: 'reports', 
                text: 'Reports', 
                link: '/reports',
                icon: <IconPieChartStroked />, 
            },
        ] 
    },
    { 
        itemKey: 'order', 
        text: 'Order', 
        icon: <IconCart />,
        items: [
            {
                itemKey: 'new_order', 
                text: 'New Order', 
                link: '/orders/new',
                icon: <IconBriefStroked />, 
            },
            {
                itemKey: 'orders', 
                text: 'My Orders', 
                link: '/orders',
                icon: <IconChecklistStroked />, 
            }
        ] 
    },
    { 
        itemKey: 'tools', 
        text: 'Tools', 
        icon: <IconWrench />,
        items: [
            {
                itemKey: 'osint',
                text: 'OSINT',
                link: '/osint',
                icon: <IconSearch />
            },
            {
                itemKey: 'ai', 
                text: 'AI', 
                link: '/ai',
                icon: <IconTerminal />, 
            },
            {
                itemKey: 'security', 
                text: 'Security', 
                link: '/security',
                icon: <IconShield />, 
            }
        ] 
    },
    { itemKey: 'settings', text: 'Settings', link: '/settings', icon: <IconSetting /> },
    { 
        itemKey: 'support', 
        text: 'Support', 
        icon: <IconCustomerSupportStroked/>,
        items: [
            {
                itemKey: 'new_request',
                text: 'New Request',
                link: '/requests/new',
                icon: <IconCommentStroked/>
            },
            {
                itemKey: 'requests', 
                text: 'My Requests', 
                link: '/requests',
                icon: <IconChecklistStroked />, 
            }
        ]
    }
];

const public_menu = [
    { itemKey: 'home', text: 'Home', link: '/home', icon: <IconHome /> },
    { itemKey: 'projects', text: 'Projects', link: '/projects', icon: <IconSourceControl /> },
    { itemKey: 'services', text: 'Services', link: '/services', icon: <IconCart /> },
    { itemKey: 'solutions', text: 'Solutions', link: '/solutions', icon: <IconTerminal /> },
    { itemKey: 'about', text: 'About', link: '/about', icon: <IconInfoCircle /> },
    { itemKey: 'contacts', text: 'Contacts', link: '/contacts', icon: <IconMailStroked1 /> },
];

class SideMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Nav
                bodyStyle={{ height: 320 }}
                items={this.props.loggedIn ? private_menu : public_menu}
                onSelect={key => console.log(key)}
                className="verticalMenu"
                header={{
                    logo: <img src={window.location.protocol + "//" + window.location.hostname + "/assets/logo_cyberrose.png"} style={{width: "128px", height: "128px"}} />,
                    text: 'CyberRose'
                }}
                style={{height: '100%'}}
            >
                <Divider margin='12px'/>
                <LoginMenu
                    behaviour={this.props.behaviour}
                    keycloak={this.props.keycloak}
                    onLoginChange={this.props.onLoginChange}
                />
            </Nav>
        );
    }
}

export default SideMenu;

